import React from "react";
import FrontEnd from "./FrontEnd";
import CommingSoon from "./CommingSoon";

const Portfolio = () => {
  return (
    <div id="portfolio">
      <FrontEnd />
      {/* <CommingSoon /> */}
    </div>
  );
};

export default Portfolio;
